import firebase from 'firebase/compat/app';

export const getCurrentUser = () => {

    //return the user UID (in local storage)
    const uid = localStorage.getItem('uid');
    const client_type = localStorage.getItem('client_type');
    if (!uid) return null;
    return {
        // on ne recupere de user que les données voulues
        id: uid,
        client_type: client_type,
    };

}
/* export const getCurrentUser = () => {
    const user = firebase.auth().currentUser;
    if (!user) return null;
    return {
        // on ne recupere de user que les données voulues
        id: user.uid,
    };
} */