import { useState} from "react";
import { useTranslation } from 'react-i18next';

function Releve({ resultReleveApp, fermerInfo}) {
  console.log('resultReleve',resultReleveApp)

  const { t } = useTranslation();
    

if(resultReleveApp && resultReleveApp[0] && resultReleveApp[0].result_net.error_statut==="no" && resultReleveApp[0] && resultReleveApp[0].type === "info")
// if(resultReleveApp && resultReleveApp[0] && resultReleveApp[0].result_useful.error_statut==="no" && resultReleveApp[0] && resultReleveApp[0].result_useful.type === "info")
{

  const data = resultReleveApp[0].result_net.data;
  return (
    <div className='PhraseContainer'>
 

        <div>
        
          <div className="infoCopieur">
          
          <p>{decodeHtml(t('error_releve_addData.printer_started', { printerName: resultReleveApp[0].printer_name, startDate: data.DateStart }))}</p>
          <p>{t('error_releve_addData.black_copies', { bwCopies: data.TotalNbRealise })}</p>
          <p>{t('error_releve_addData.color_copies', { colorCopies: data.TotalCouleurRealise })}</p>
          <p>{t('error_releve_addData.scans', { scans: data.TotalScanRealise })}</p>
          <p>{t('error_releve_addData.additional_counting', { additional: data.additional_data !== null ? data.additional_data : '' })}</p>
          </div>
          {/* <div className="infoCopieur">
          
          {resultReleveApp[0].nom} &nbsp; a débuté le {resultReleveApp[0].result_useful.data.Date_start}  &nbsp; 
            Copies en noir et blanc : {resultReleveApp[0].result_useful.data.TotalNbRealise}  &nbsp; 
            Copies en couleurs : {resultReleveApp[0].result_useful.data.TotalCouleurRealise}  &nbsp; 
            Scans : {resultReleveApp[0].result_useful.data.TotalScanRealise} &nbsp; 
            Comptage additionnel:
            {resultReleveApp[0].result_useful.data.additional_data !== null ? resultReleveApp[0].result_useful.data.additional_data : null
}
          </div> */}
          
     
          <button className='fermerInfo' 
          onClick={fermerInfo}
          >{t('error_releve_addData.btn_close')}</button>
        </div>
   
    
    </div>
  );
}
else  {
 
   
return (
        <> 
          <div className='PhraseDisconnect'>      
            <div className="erreurCopieur">
                <span>{resultReleveApp[0].printer_name} </span>
                <span>{resultReleveApp[0].result_brut.error_status} </span> 
                <span>{resultReleveApp[0].result_brut.error_txt} </span>
                {/* <span>{resultReleveApp[0].result_brut.error.error_details} </span> */}
            </div>
            <button className='fermerInfo' onClick={fermerInfo}>{t('error_releve_addData.btn_close')}</button>
          </div>  
        </>
      )
/* return (
        <> 
          <div className='PhraseDisconnect'>      
            <div className="erreurCopieur">
                <span>{resultReleveApp[0].nom} </span>
                <span>{resultReleveApp[0].result_useful.error.error_electron} </span> 
                <span>{resultReleveApp[0].result_useful.error.error_txt} </span>
                <span>{resultReleveApp[0].result_useful.error.error_details} </span>
            </div>
            <button className='fermerInfo' onClick={fermerInfo}>Fermer</button>
          </div>  
        </>
      ) */
}
}

function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}
export default Releve;
