import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi) // Utilise le backend HTTP pour charger les fichiers de traduction
  .use(initReactI18next) // Initialise le plugin react-i18next
  .init({
    supportedLngs: ['en', 'fr'], // Remplacez ceci par les langues que vous supportez
    fallbackLng: "en", // Langue à utiliser si la langue actuelle n'est pas disponible
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json', // Le chemin vers les fichiers de traduction
    },
    // debug: true
  });

export default i18n;
