// import firebase from 'firebase/compat/app';
// fonction qui charge données du copieur selectionné ds doc electron_uid et qui en meme tps met le statut-processing à todo
export const setAction = async(nom,idcop, ipcop, modcop, clientUid, type, action_type) =>{

// sans batch 
/*await firebase.firestore().collection('customers').doc(clientUid).collection('list_copieurs').doc(`electron_${clientUid}`)
.update({"ip_to_read": ipcop,"modele": modcop, "idCopieur": idcop, type:type, etat:etatLibre, "nom":nom})
console.log("ip_to_read", ipcop)
}*/

// Requête POST pour envoyer les infos au cloud function
let url = "";
if (process.env.NODE_ENV === 'development') {
  url = "https://react-cloudfunction-iru7ymzqca-uc.a.run.app"; 
} else {
  url = "https://react-cloudfunction-opdnvnmzja-uc.a.run.app";
}

try {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      action : 'action_start_stop_info',
      userUid: clientUid,
      printerId: idcop,
      updateData: {
        "action_status": "todo",
        "status": "todo",
        "type": type
      }
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
} catch (error) {
  console.error('Erreur lors de l\'envoi de la requête POST:', error);
}


/* const db = firebase.firestore();
let currentBatch = db.batch();
let currentBatchSize = 0;
const batches = [ currentBatch ];

 const ref4 = firebase.firestore().collection('customers')
 .doc(clientUid).collection('printing_counters').doc(idcop)

// On passe le action_status à todo + status à todo + type à start/stop/info
currentBatch.update(ref4,{"action_status": "todo", "status": "todo", "type": type})
currentBatchSize++;
// console.log('size2', currentBatchSize)

if (currentBatchSize === 498) {
    currentBatch = db.batch();
    batches.push(currentBatch);
    // on remet le compteur
    currentBatchSize = 1;
  }


//await batch.commit() 
await Promise.all(batches.map(batch => batch.commit()));
 */

}
/* export const setAction = async(nom,idcop, ipcop, modcop, clientUid, type, etatLibre) =>{

// sans batch 
// await firebase.firestore().collection('customers').doc(clientUid).collection('list_copieurs').doc(`electron_${clientUid}`)
// .update({"ip_to_read": ipcop,"modele": modcop, "idCopieur": idcop, type:type, etat:etatLibre, "nom":nom})
// console.log("ip_to_read", ipcop)
// }


const db = firebase.firestore();
let currentBatch = db.batch();
let currentBatchSize = 0;
const batches = [ currentBatch ];

 const ref2=firebase.firestore()
        .collection('customers')
        .doc(clientUid)
        .collection('list_copieurs')
        .doc(`electron_${clientUid}`)
      
        

        currentBatch.update(ref2,{"ip_to_read": ipcop,"modele": modcop, "idCopieur": idcop, type:type, etat:etatLibre, "nom":nom});
        currentBatchSize ++;
       

 const ref4 = firebase.firestore().collection('customers')
 .doc(clientUid).collection('list_copieurs').doc(idcop)


currentBatch.update(ref4,{"statut_processing": "todo"})
currentBatchSize++;
console.log('size2', currentBatchSize)

if (currentBatchSize === 498) {
    currentBatch = db.batch();
    batches.push(currentBatch);
    // on remet le compteur
    currentBatchSize = 1;
  }


//await batch.commit() 
await Promise.all(batches.map(batch => batch.commit()));

} */