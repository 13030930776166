import CopieurItem from '../CopieurItem/CopieurItem.jsx';
import {useSelector} from 'react-redux';


function CopieurList({ donnees,showReleve, handleErreursDemarrer, clientUid, handleResultReleve, handleResultStart, handleErrorElectron, fermerInit, handleAdditionalData,setAfficherAddData, setAfficherErreur, setAfficherReleve, handleCopieurId}) {
    
    // console.log('alldata COPIEUR LIST', donnees)


    // on affiche la liste des copieurs récupérés
    //const cartItems = useSelector((store) => store.start.cartItems);  
    /*return (
        cartItems.map((data,i) =>
            <div key={data.IdCopieur} id={"copieur_" + data.IdCopieur}>
                {<CopieurItem
                    key={i} id={data.IdCopieur} 
                    donnees={data}
                    showReleve={showReleve} downloadRequest={downloadRequest}
                    desactivateButtons={desactivateButtons} handleErreursDemarrer={handleErreursDemarrer}
                    handleCopieurDisconnect={handleCopieurDisconnect}
                    clearCopieurDisconnect={clearCopieurDisconnect}
                />}
            </div>)
    )*/
   


    if(donnees){
    return (

        donnees?.map(data =>
            <div key={data.id} id={"copieur_" + data.id}>
                {<CopieurItem
                    key={data.id}
                    donnees={data}  
                    showReleve={showReleve}
                     handleErreursDemarrer={handleErreursDemarrer}
                    clientUid={clientUid} handleResultReleve={handleResultReleve} handleResultStart={handleResultStart} handleErrorElectron={handleErrorElectron} fermerInit={fermerInit}
                    handleAdditionalData={handleAdditionalData}
                    setAfficherAddData={setAfficherAddData} setAfficherErreur={setAfficherErreur} setAfficherReleve={setAfficherReleve}
                    handleCopieurId={handleCopieurId}
                   
                />}
            </div>)
    
            
    )
                }else{
                    return null;
                }
}

export default CopieurList;