import React, { useState } from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import { ProtectedRoute } from '../auth/ProtectedRoute.jsx';
import Home from './Home.jsx'
import {Deconnexion} from './Deconnexion'
// import { useAuth } from '../auth/useAuth';
import SignInForm from "../auth/signInForm";


function App() {
// recuperer email et pwd de url
  const queryParameters = new URLSearchParams(window.location.search)

  // const email = queryParameters.get("email")
  // const pwd = queryParameters.get("password")
  const uid = queryParameters.get("uid")
  const debug = queryParameters.get('debug') === 'true';

// verifier a tout instant utilisateur connecté, s'il est connecte acces à la route protégée Home
  // const {user, isInitializing}  = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('uid') ? true : false);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  // const[allData,setAllData]=useState([])
  // const[clientUidApp,setClientUidApp]=useState(null)
  // const [emailValue, setEmailValue] = useState(email);
  // const [passwordValue, setPasswordValue] = useState(pwd);
  const [uidValue, setUidValue] = useState(uid);
  
// fonction passée ds SignInForm pour recuperer uid de connexion 
  // function envoyerClientUid(donnee){
  //   setClientUidApp(donnee)
    
  // }

  // function envoyerData(donnee){
  //   setAllData(emp => ([...donnee]));
   
  // }

  // console.log('userAuth', isAuthenticated)


  return(
     <Router>
       <Switch>
       <Route path="/" exact>
          <SignInForm uid={uidValue} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} setUidValue={setUidValue} debug={debug} />
          {/* <SignInForm email={emailValue} pwd={passwordValue}envoyerClientUid={envoyerClientUid} envoyerData={envoyerData}/> */}
         </Route>
       <Route path="/deconnexion" exact>
          <Deconnexion />
         </Route>
         <ProtectedRoute path="/home" exact isAuthed={isAuthenticated} uid={uidValue}
         ><Home /*allData={allData}*/ clientUid={uid}  />
         </ProtectedRoute>
       </Switch>
        {/* Route pour gérer les chemins non trouvés */}
        <Route path="*">
          <Redirect to="/" /> {/* Redirige vers la page d'accueil */}
        </Route>
     </Router>
     );
}


export default App;

