import './Footer.css';
import elyFooter from '../../assets/logo-elyote-footer.png'
import packageJson from '../../../package.json';
// import { getCurrentUser } from '../../auth/getCurrentUser';
// import { useEffect, useState } from 'react';
// import { use } from 'i18next';

function Footer() {

/*   const [infoLoaded, setInfoLoaded] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search)
  const userUid = queryParameters.get("uid")

  // On récupère le name et email de l'utilisateur dans le local storage
    const name = localStorage.getItem('name');
    const email = localStorage.getItem('email');

    // On écoute les changements dans le local storage pour être sûr de récupérer les bonnes informations pour helpcrunch
    useEffect(() => {
        const handleStorageUpdate = () => {
            setInfoLoaded(true);
        };
        
        window.addEventListener('storageUpdated', handleStorageUpdate);
        
        // Nettoyage
        return () => {
            window.removeEventListener('storageUpdated', handleStorageUpdate);
        };
        }, []);
    

  useEffect(() => {

    if(userUid && infoLoaded){

        console.log('userUid', userUid)
        console.log('name', name)
        console.log('email', email)


      // Définir les paramètres de HelpCrunch
      window.helpcrunchSettings = {
          organization: 'elyote',
          appId: '6d0fce13-b9ab-41a0-9772-fe0de3c32e6d',
          user: {
              name: `SC - ${ name }`,
              email: `${ email }`,
              user_id: userUid 
          }
      };

      // Script pour charger le widget HelpCrunch
      (function(w, d) {
          var hS = w.helpcrunchSettings;
          if (!hS || !hS.organization) { return; }
          var widgetSrc = 'https://' + hS.organization + '.widget.helpcrunch.com/';
          w.HelpCrunch = function() { w.HelpCrunch.q.push(arguments) };
          w.HelpCrunch.q = [];
          function r() {
              if (d.querySelector('script[src="' + widgetSrc + '"]')) { return; }
              var s = d.createElement('script');
              s.async = 1; s.type = 'text/javascript'; s.src = widgetSrc;
              (d.body || d.head).appendChild(s);
          }
          if (d.readyState === 'complete' || hS.loadImmediately) {
              r();
          } else if (w.attachEvent) {
              w.attachEvent('onload', r);
          } else {
              w.addEventListener('load', r, false);
          }
      })(window, document);
    }
  }, [userUid, infoLoaded, name, email]); */
  return (
      <div className="Footer">
          <p id="copyright">© Simply Copy by Elyote</p>
          <p>Version: {packageJson.version}</p>
          <img src={elyFooter} className='ElyLogo' alt='ElyLogo' onClick={() => window.open("https://www.elyote.com/")}></img>
      </div>
  );
}

export default Footer;
