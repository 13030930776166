import { useTranslation } from 'react-i18next';

function AddData({ resultAddData, resultNom, fermerInfo}) {

  const { t } = useTranslation();

if(resultAddData && resultNom)
{
  return (
    <div className='PhraseContainer'>
 
        <div>
            <div className="infoCopieur">
          
                {resultNom}  &nbsp; 
                {/* Comptage additionnel : {resultAddData}  */}
                {t('error_releve_addData.additional_counting', { additional: resultAddData })}
        
            </div>

            <button className='fermerInfo' 
            onClick={fermerInfo}
            >{t('error_releve_addData.btn_close')}</button>
          
        </div>
   
    </div>
  );
}
    
}
export default AddData;
