import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const ProtectedRoute = ({ isAuthed, uid, isLoading, ...props }) => {
  
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Si l'utilisateur n'est pas authentifié mais qu'il accède à la page (VIA FAVORIS) /home?uid={uid} => on le connecte via la redirection vers /uid={uid}
  if (!isAuthed && uid) {
    return <Redirect to='/'/>
  }

  // Si l'utilisateur n'est pas authentifié, on le redirige vers la page de deconnexion
  if (!isAuthed || uid !== localStorage.getItem('uid')) {
    return <Redirect to='/deconnexion'/>
   
  }

  return (
    <Route {...props}/>
     
  );
}


