import './CopieurItem.css';
import Start1 from '../Start/Start1';


function CopieurItem({ donnees,handleErreursDemarrer, clientUid, handleResultReleve, handleResultStart, handleErrorElectron, fermerInit, handleAdditionalData,  setAfficherAddData, setAfficherErreur, setAfficherReleve, handleCopieurId}) {

   if(donnees ){
    return (

        <div className="CopieurItem">
          <div className='headerBox'></div>
          <h1 className='copieurName'>{donnees.printer_name}</h1>
        
        {/* TODO : Supprimer les champs inutile ? etat (=type ? busy or free) et ChangEtatDate*/}
          <Start1 nom={donnees.printer_name} id={donnees.id} choix_modele={donnees.model_id} ip={donnees.printer_ip} etat={donnees.type} start={donnees.ChangEtatDate} handleErreursDemarrer={handleErreursDemarrer} clientUid={clientUid} handleResultReleve={handleResultReleve} handleResultStart={handleResultStart} handleErrorElectron={handleErrorElectron}
          fermerInit={fermerInit} donnees={donnees}
          handleAdditionalData={handleAdditionalData}
          setAfficherAddData={setAfficherAddData} setAfficherErreur={setAfficherErreur} setAfficherReleve={setAfficherReleve}
          handleCopieurId={handleCopieurId}
         
          />
          
        </div>
    );
  }else{
    return null;
}
}



export default CopieurItem;

   
        {/* ANCIENNE DATA
        
        <div className="CopieurItem">
          <div className='headerBox'></div>
          <h1 className='copieurName'>{donnees.nom}</h1>
        
          <Start1 nom={donnees.nom} id={donnees.id} choix_modele={donnees.modele} ip={donnees.ip_locale} etat={donnees.etat} start={donnees.ChangEtatDate} handleErreursDemarrer={handleErreursDemarrer} clientUid={clientUid} handleResultReleve={handleResultReleve} handleResultStart={handleResultStart} handleErrorElectron={handleErrorElectron}
          fermerInit={fermerInit} donnees={donnees}
          handleAdditionalData={handleAdditionalData}
          setAfficherAddData={setAfficherAddData} setAfficherErreur={setAfficherErreur} setAfficherReleve={setAfficherReleve}
         
          />
          
        </div> */}