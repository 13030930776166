import React from "react";
import { useTranslation } from 'react-i18next';

function ErrorsList({resultReleveApp, fermerErreurs, erreurElectron, nomErreurElectron, initHome }) {

    const { t } = useTranslation();

   console.log('result_net passé à ErrorList', resultReleveApp )
   console.log('ErrorElectron passée à ErrorList',erreurElectron )

   if(resultReleveApp && resultReleveApp[0] && resultReleveApp[0].result_net.error_statut==="yes" /*&& initHome===false*/) {


       return (
        <>
        
          <div className='PhraseDisconnect'>
            
                <div className="erreurCopieur">
                    {/* <span>{resultReleveApp[0].result_net.error_statut} </span> */}
                    <span dangerouslySetInnerHTML={{ __html: t(renderErrorMessage(resultReleveApp[0].result_net.error.error_txt), { printer_name: resultReleveApp[0].printer_name }) }} />
                </div>
                <button className='fermerInfo' onClick={fermerErreurs}>{t('error_releve_addData.btn_close')}</button>
           
            
            </div>  
        </>
       );

    }else if(resultReleveApp && resultReleveApp[0] && resultReleveApp[0].result_net.error_statut==="no"&& resultReleveApp[0].result_net.data.notification /*&& initHome===false*/ ){
        return (
            <>
            
              <div className='PhraseDisconnect errorNotification'>
                
                    <div className="erreurCopieur">
                        <span>{resultReleveApp[0].nom} </span>
                        <span>{resultReleveApp[0].result_net.data.notification.error_txt} </span>
                        <span>{formatErrorMessage(resultReleveApp[0].result_net.data.notification.error_details)} </span>
                
                    </div>
                    <button className='fermerInfo' onClick={fermerErreurs}>{t('error_releve_addData.btn_close')}</button>
               
                
                </div>  
            </>
           );
    }
        
    if(erreurElectron){
        return(
            <div className='PhraseDisconnect'>            
                <div className="erreurCopieur">
                    {/* <span>{nomErreurElectron}: {t('errorList.error_simplycopy')}</span> */}
                    <span dangerouslySetInnerHTML={{ __html: t('errorList.error_simplycopy', { printer_name: nomErreurElectron }) }} />
                </div>
                <button className='fermerInfo' onClick={fermerErreurs}>{t('error_releve_addData.btn_close')}</button>
   
            </div>  

        );     
    }
}

function renderErrorMessage(errorTxt) {

    // console.log('errorTxt', errorTxt)
    switch (errorTxt) {
      case 'printer_no_response':
        return 'errorList.no_response';
      case 'printer_error_get_data':
        return 'errorList.error_get_data';
      case 'printer_error_ip':
        return 'errorList.error_ip';
      default:
        return 'errorList.error_simplycopy';
    }
  }

// Fonction pour formater le message d'erreur en faisaint des sauts de ligne à chaque phrase débutant par "Le solde"
function formatErrorMessage(errorDetails) {
    if (!errorDetails) return null;

    // console.log('errorDetails', errorDetails);

    return (
        <div>
            {errorDetails.split('<br>').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    {index < errorDetails.split('<br>').length - 1 && <br />}
                </React.Fragment>
            ))}
        </div>
    );
    

    // <div>{errorDetails}</div>
  
    // Divise la chaîne de caractères en un tableau de sous-chaînes
    /* const sentences = errorDetails.split('Le solde').slice(1); // slice(1) pour éliminer le premier élément vide si la chaîne commence par "Le solde"
  
    // Crée un nouvel élément pour chaque sous-chaîne et les réunit avec un séparateur de ligne
    return sentences.map((sentence, index) => (
      <div key={index}>Le solde {sentence.trim()}</div>
    )); */
  }

/* function ErrorsList({resultReleveApp, fermerErreurs, erreurElectron, nomErreurElectron, initHome }) {
   console.log('result_useful passé à ErrorList', resultReleveApp )
   console.log('ErrorElectron passée à ErrorList',erreurElectron )

   if(resultReleveApp && resultReleveApp[0] && resultReleveApp[0].result_useful.error_statut==="yes" ) {


       return (
        <>
        
          <div className='PhraseDisconnect'>
            
                <div className="erreurCopieur">
                    <span>{resultReleveApp[0].result_useful.error.error_electron} </span> 
                    <span>{resultReleveApp[0].result_useful.error.error_txt} </span>
                    <span>{resultReleveApp[0].result_useful.error.error_details} </span>
            
                </div>
                <button className='fermerInfo' onClick={fermerErreurs}>Fermer</button>
           
            
            </div>  
        </>
       );

    }else if(resultReleveApp && resultReleveApp[0] && resultReleveApp[0].result_useful.error_statut==="no"&& resultReleveApp[0].result_useful.data.notification  ){
        return (
            <>
            
              <div className='PhraseDisconnect'>
                
                    <div className="erreurCopieur">
                        <span>{resultReleveApp[0].nom} </span>
                        <span>{resultReleveApp[0].result_useful.data.notification.error_txt} </span>
                        <span>{resultReleveApp[0].result_useful.data.notification.error_details} </span>
                
                    </div>
                    <button className='fermerInfo' onClick={fermerErreurs}>Fermer</button>
               
                
                </div>  
            </>
           );
    }
        
    if(erreurElectron){
        return(
            <div className='PhraseDisconnect'>            
                <div className="erreurCopieur">
                    <span>{nomErreurElectron}: Problème de connexion au client “Simply Copy”, merci de consulter la documentation pour plus de détails.</span>
               
                </div>
                <button className='fermerInfo' onClick={fermerErreurs}>Fermer</button>
   
            </div>  

        );     
    }
} */

export default ErrorsList;