import firebase from 'firebase/compat/app';
import { useState, useEffect } from 'react';

const useGetUserClientType = (clientUid) => {
  const [clientType, setClientType] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = firebase.firestore();
        const doc = await db.collection('customers').doc(clientUid).get();
        if (doc.exists) {
          setClientType(doc.data().client_type);
        } else {
          console.log("Le document n'existe pas !");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération du document: ", error);
      }
    };

    fetchData();
  }, [clientUid]);

  return clientType;
};

export default useGetUserClientType;