import { FaPlay, FaStopCircle } from "react-icons/fa"
import './Start.css'
import React, { useState, useEffect, useRef } from "react"
import PuffLoader from "react-spinners/PuffLoader"
import {setAction} from './setAction';
import {listenResult} from './listenResult';
import {updateInit} from './updateInit';
import infoLogo from '../../assets/Info.svg';
import historiqueLogo from '../../assets/Historique.svg';
import { getCurrentUser } from '../../auth/getCurrentUser';
import useGetUserClientType from "../../auth/getUserClientType";
import { useTranslation } from 'react-i18next';

const override ={
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
}

function Start1({id, ip, nom, choix_modele, clientUid, handleResultReleve, handleResultStart, handleErrorElectron, handleAdditionalData, setAfficherAddData, setAfficherErreur, setAfficherReleve, handleCopieurId}) {

  const[resultOnReactApp,setResultOnReactApp]= 
  useState([])
  const[resultCouleur, setResultCouleur]= useState(null)
  const[resultNb, setResultNb]= useState(null)
  const[resultScan, setResultScan]= useState(null)
  const[resultAddData, setResultAddData]= useState(null)
  const[resultDebut, setResultDebut]= useState('')
  const[resultDuree, setResultDuree]= useState('')
  const[resultFin, setResultFin]= useState('')
  const[resultEtat, setResultEtat]= useState(null)
  const[usageDefault, setUsageDefault]= useState(null)
  const[spinner, setSpinner]= useState(false)
  const[spinner2, setSpinner2]= useState(false)
  const[etatLibre, setEtatLibre]=useState(null)
  const[ipLocale, setIpLocale]=useState("")
  const[electronEtat, setElectronEtat]= useState('');
  const[idCopieur, setIdCopieur]= useState('');
  const[copieurActivated, setCopieurActivated]= useState(true)
 
  const initialize = useRef(true)
  const currentUser = getCurrentUser()
  const userClientType = useGetUserClientType(clientUid);
  const { t } = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);
  const debug = urlParams.get('debug');

 // initialiser les données initiales
useEffect(() => {

  if (debug === 'true') console.log("Initialisation du copieur ", id, " - ", Date.now());
  // console.log('Initialisation du copieur ', id, ' - ', Date.now());
  
  const initCopieur = async () => {

    setIdCopieur(id)
    const resultInit= await updateInit(id,clientUid);
    initialize.current= true
    setIpLocale(resultInit.ipInit)
  setUsageDefault(resultInit.usageDefault) //Get the usage_default (libre-service OR main-doeuvre) from resultInit.usageDefault

    if (debug === 'true') console.log("resultInit", resultInit);
    // console.log("resultInit", resultInit)
    if(resultInit.error==="no"){
      if(resultInit.etatLibre === "busy"){
        setResultEtat(false)
        setEtatLibre(false)
        
        setResultDebut(resultInit.dataInit.result_net.data.DateStart)
      }else if(resultInit.etatLibre === "free" && resultInit.dataInit.result_net.data && resultInit.dataInit.result_net.data !== ''){
        setResultEtat(true)
        setEtatLibre(true)
        setResultCouleur(resultInit.dataInit.result_net.data.TotalCouleurRealise);
        setResultNb(resultInit.dataInit.result_net.data.TotalNbRealise);
        setResultScan(resultInit.dataInit.result_net.data.TotalScanRealise);
        setResultAddData(resultInit.dataInit.result_net.data.additional_data);
        setResultDebut(resultInit.dataInit.result_net.data.DateStart)
        setResultDuree(resultInit.dataInit.result_net.data.Duree)
        setResultFin(resultInit.dataInit.result_net.data.DateEnd)
      }
    }

     // A la création d'un copieur depuis simply-copy.com => l'état est par défaut vide "" (pas free ou busy)
     if(resultInit.etatLibre === ""){
      setResultEtat(true)
      setEtatLibre(true)
    }

    // Si le copieur a été desactivé par l'utilisateur, on "grise" le bouton start
    if(resultInit.dataInit.activated === false){
      setCopieurActivated(false)
    }
  }

  initCopieur();

}, []);

// fonction executee qd on clique sur start
const updateFirebase_start = async () => {

  if (debug === 'true') console.log('Printer ', id, ' - start(début) - ', Date.now());
    
    await setAction(nom,id,ip, choix_modele, clientUid, "start", "printing_counters");

    initialize.current= false
  
    setSpinner(true)
    // donnees recupérées ici remontees à Home par fonctions
    // FIX 12/2023 : on ferme l'erreur seulement si user clique sur 'fermer' (voir fermerErreurs)
    // setAfficherAddData(false);
    // setAfficherErreur(false);
    // setAfficherReleve(false);  
}


// fonction executee qd on clique sur stop
const updateFirebase_stop = async () => {

  if (debug === 'true') console.log('Printer ', id, ' - stop(début) - ', Date.now());

    await setAction(nom,id,ip, choix_modele,clientUid, "stop", "printing_counters");
    initialize.current= false
    setSpinner(true)
    // setAfficherAddData(false); //FIX DU ADDITIONNAL_DATA QUI NE S'AFFICHE PAS QUAND ON CLIQUE SUR LE BOUTON STOP
    // setAfficherErreur(false); // FIX 12/2023 : on ferme l'erreur seulement si user clique sur 'fermer' (voir fermerErreurs)
    // setAfficherReleve(false);
}


// fonction executee qd on clique sur info
const updateFirebase_info = async () => {

  console.log('Printer ', id, ' - info(début) - ', Date.now())

    await setAction(nom,id,ip, choix_modele, clientUid, "info", "printing_counters");
    initialize.current= false
    setSpinner2(true)
    // FIX 12/2023 : on ferme l'erreur seulement si user clique sur 'fermer' (voir fermerErreurs)
    // setAfficherAddData(false);
    // setAfficherErreur(false);
}


// a chaque chgt ds firestore ce useeffect se produit
useEffect(() => {
    const unsubscribe = listenResult(id,clientUid,results => {
    // console.log('resultat de ecoute sur firestore', results)
    initialize.current= false // MODIF ANTOINE : permet d'éviter le problème de syncro entre le résultat de l'initialisation et le résultat de l'écoute
  // useref initialize l 132 permet de controler que setResultOnReactApp ne s'execute pas a l'initialisation eviter un rerender ms pb de synchronisation donc on laisse le rerender se faire.
  //j'ai remonte initialize l 123
   if (results && results.length >0 && initialize.current===false){

    setResultOnReactApp(results);
  }
  // initialize.current= false
 
  });

  return unsubscribe;
}, []);


// TODO : à supprimer, ne sert juste pour forcer une erreur pour TEST 
// IMPORTANT : remplacer resultOnReactApp par ErrorTEST dans la fonctione useEffect ci-dessous pour TEST
const modifiedResultOnReactApp = [...resultOnReactApp]; // Clone the array
modifiedResultOnReactApp[0] = { ...modifiedResultOnReactApp[0] }; // Clone the object

// Modify the error_statut to "yes"
modifiedResultOnReactApp[0].result_net = {
  ...modifiedResultOnReactApp[0].result_net,
  error_statut: "yes",
};
/* modifiedResultOnReactApp[0].result_useful = {
  ...modifiedResultOnReactApp[0].result_useful,
  error_statut: "yes",
}; */

const ErrorTEST = modifiedResultOnReactApp[0];

// FIN TODO

useEffect(() => {

  // console.log('resultOnReactApp', resultOnReactApp)

  if (resultOnReactApp && resultOnReactApp.length > 0 && resultOnReactApp[0].status === "done") {

    // On fais passer l'id du copieur à Home pour que si on clique sur 'fermer' on cache et efface dans Firestore le relevé ou les erreurs
    handleCopieurId(id)

    // console.log('Le status du resultat de ecoute sur firestore est à DONE, on met à jour le state avec les données récupérées')
  
    if(resultOnReactApp[0].type==="info")
    {
      // setType(resultOnReactApp[0].type) // On force le type à "info" pour afficher le relevé d'info
      setResultOnReactApp(resultOnReactApp);
      handleResultReleve(resultOnReactApp, resultOnReactApp[0].result_net.error_statut)
      setSpinner2(false)
    }
    
  if(resultOnReactApp[0].result_net.error_statut === "no" && resultOnReactApp[0].result_net.data && resultOnReactApp[0].result_net.data !== null)
  {
    // effacer message erreur précédent (s'il existe)
    handleResultStart(null, "no");

    // START
    if(resultOnReactApp[0].type === "start")
    {
      setResultDebut(resultOnReactApp[0].result_net.data.DateStart);
    } 

    // STOP
    if (resultOnReactApp[0].type === "stop") {

      if(resultOnReactApp[0].result_net.data.TotalCouleurRealise && resultOnReactApp[0].result_net.data.TotalCouleurRealise !== ''){
        

        // console.log('will - Stop');
        // console.log('will - Stop TotalCouleurRealise - ', resultOnReactApp[0].result_net.data.TotalCouleurRealise)

        setResultCouleur(resultOnReactApp[0].result_net.data.TotalCouleurRealise);
        setResultNb(resultOnReactApp[0].result_net.data.TotalNbRealise);
        setResultScan(resultOnReactApp[0].result_net.data.TotalScanRealise);
        setResultDuree(resultOnReactApp[0].result_net.data.Duree);
        setResultDebut(resultOnReactApp[0].result_net.data.DateStart);
        setResultFin(resultOnReactApp[0].result_net.data.DateEnd);
      } else {
        console.log('will - ERREUR SUR LE STOP CAR VALEURS VIDES');
      }

      // Si données additionnelles
      if (resultOnReactApp[0].type === "stop" && resultOnReactApp[0].result_net.data.additional_data && resultOnReactApp[0].result_net.data.additional_data !== null){

        console.log('will - Stop avec additional_data');
        setResultOnReactApp(resultOnReactApp);
        handleAdditionalData(resultOnReactApp[0].result_net.data.additional_data, nom)
      }
    }

    setSpinner(false)

    if (resultOnReactApp[0].printer_status === "busy"){
      setEtatLibre(false)
      setResultEtat(false)
    }
    else if (resultOnReactApp[0].printer_status === "free"){
      setEtatLibre(true)
      setResultEtat(true)
    }

    // Si erreur de notification
    if (resultOnReactApp[0].result_net.data.notification && resultOnReactApp[0].result_net.data.notification !== null && Object.keys(resultOnReactApp[0].result_net.data.notification).length !== 0) {
      handleResultStart(resultOnReactApp, 'yes')
      setResultCouleur(null);
      setResultNb(null);
      setResultScan(null);
      setResultDuree('');
      setResultDebut('');
      setResultFin('');
      setSpinner(false)
      if (resultOnReactApp[0].printer_status === "busy"){
        setEtatLibre(false)
        setResultEtat(false)
      }
      else if (resultOnReactApp[0].printer_status === "free"){
        setEtatLibre(true)
        setResultEtat(true)
      }
    }
  }
  else if(resultOnReactApp[0].result_net.error_statut === "yes")
  {
    console.log('DEBUG : ERREUR_STATUT = YES')
    handleResultStart(resultOnReactApp,resultOnReactApp[0].result_net.error_statut)
    setResultCouleur(null);
    setResultNb(null);
    setResultScan(null);
    setResultDuree('');
    setResultDebut('');
    setResultFin('');
    setSpinner(false)
    if (resultOnReactApp[0].printer_status === "busy"){
      setEtatLibre(false)
      setResultEtat(false)
    }
    else if (resultOnReactApp[0].printer_status === "free"){
      setEtatLibre(true)
      setResultEtat(true)
    }
  }else
  {
  //console.log("nothing")
  }

  if (debug === 'true') {
    console.log('Printer ', id, ' - ', resultOnReactApp[0].type, '(fin) - ', Date.now())
    console.log("Données Firestore traitées : ",resultOnReactApp)

  }
  }
  // else{ 
  //   console.log('Le status du resultat de ecoute sur firestore est à TODO, on ne fait rien')
  // }
}, [resultOnReactApp]);


// erreur si au bout de 10 secondes statut_processing toujours a todo, signifie pas de connexion user à electron
useEffect(() => {

  let timer1;

if(spinner || spinner2){
  // console.log("ID du copieur", id)
  // console.log("resultOnReactApp", resultOnReactApp)
  timer1 = setTimeout(() => 
  {
    console.log("check if status is 'done' before 10sec: status = ", resultOnReactApp[0].status)
    if(resultOnReactApp[0].status && resultOnReactApp[0].status==="todo"){
      setSpinner(false)
      setSpinner2(false)
      console.log("electron not connected")
      handleErrorElectron(nom)
  }
}
  , 10000);
      return () => {
        clearTimeout(timer1);
      };
     
    }
}, [spinner, spinner2]);


if(resultOnReactApp === null) 

return null;


return (
  <>
   
    <div className='infoBox'>
      <h1>{electronEtat}</h1>
      <button className='historique' onClick={() => window.open(`https://www.simply-copy.com/printer-view.php?view=history&printer=${id}&uid=${currentUser.id}`)}>
        <img src={historiqueLogo} className='historiqueLogo' alt='historiqueLogo'></img>
        <h3>{t('start1.history')}</h3>
      </button>
      { spinner2
        ?
          <div className="details">
              <PuffLoader
                color={"crimson"} loading={true} css={override} size={25} />
          </div>
        
        : 

          <button className='details'  onClick={updateFirebase_info} disabled={resultEtat}> 
            <img src={infoLogo} className='infoLogo' alt='infoLogo'></img>
            <h3>{t('start1.statement')}</h3>
          </button>
}
    </div>

    { !copieurActivated 
      ?
      <div className='copieurDesactive divOccupe backg'>
        <span>{t('start1.disabled')}</span>
      </div>
      :
      <div className={`divOccupe ${resultEtat? "backv" : "backr"}`}>
        <span>{ resultEtat? (t('start1.free')) : (t('start1.busy'))}</span>
      </div>
    }
    
    <div className='nbStat'>
      <div className='nbColonne'>
        <div className={`${copieurActivated ? "square" : "squareDesactivated"}`}>
          <h1 className="">{!etatLibre ? '' :resultNb}</h1>
        </div>
        <h2 className='nbStatType'>
          {t('start1.black&white')}
        </h2>
      </div>
      <div className='nbColonne'>
        <div className={`${copieurActivated ? "square" : "squareDesactivated"}`}>
          <h1 className="">{!etatLibre ? '' :resultCouleur}</h1>
        </div>
            <h2 className='nbStatType'>
              {t('start1.colors')}
            </h2>
      </div>
      <div className='nbColonne'>
        <div className={`${copieurActivated ? "square" : "squareDesactivated"}`}>
          <h1 className="">{!etatLibre ? '' :resultScan}</h1>
        </div>
          <h2 className='nbStatType'>
            {t('start1.scan')}
          </h2>
      </div>
     </div>
   

      <div className='timeZone'>
        { resultEtat ? 
          <>
            <h2>{t('start1.start')}: {resultDebut}</h2>
            <h2>{t('start1.end')}: {resultFin}</h2>
            <h2>{t('start1.time')}: {resultDuree}</h2>
          </>
                   :
            <h2>{t('start1.start')}: {resultDebut}</h2>
        }    
      </div>
       

      <div> 
        { spinner 
        ?
          <div className="sweet-loading">
              <PuffLoader
                color={"crimson"} loading={true} css={override} size={65} />
          </div> 
        : 
          <>

            { !copieurActivated 
            ?
              <div className='copieurDesactive'>
                <button className="desactiveCircle" disabled={true}>
                  <FaPlay className="startButton"/> 
                </button>
              </div>
            :
            <>
              { 
                //resultatEtat true libre donc bouton start  
                resultEtat 
                ? 
                    <button className="startCircle"  onClick={updateFirebase_start}>
                      <FaPlay className="startButton"/> 
                    </button>  
                :    

                    <button className="stop"  onClick={updateFirebase_stop} >
                      <FaStopCircle className="startButton"  /> 
                    </button>
              }
            </>
            }
         </>
        }
      </div>

      {resultEtat && userClientType === 'BV'
        ?
          <div className='copieurInformation'>
            <button onClick={() => window.open(`https://bv.elyote.com/espace-services.php?cart_type=releve&print_black=${resultNb}&print_color=${resultCouleur}&scan=${resultScan}&type_service=${usageDefault}`)}>{t('start1.send_to_elyote')}</button>
          </div> 
        : 
        <div></div>
      }
     
  </>
)

}

export default Start1;



