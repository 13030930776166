import firebase from 'firebase/compat/app';
// import { getCurrentUser } from '../auth/getCurrentUser';

// recuperer donnees de tous les copieurs de utilisateur connecte
export const getUserData = async userId => {
    
// console.log(userId,"userid")
    const querySnapshot = await firebase.firestore()
        .collection('customers')
        .doc(userId)
        .collection('printing_counters')
        .get()
// console.log("querysnap", querySnapshot)
     const resultat  = querySnapshot.docs.map((doc) => (
               
             {   ...doc.data(),
                id: doc.id,
                //uid: userId
            
            }
        
        ));

        // Tri des résultats en fonction du champ 'printer_order'
        resultat.sort((a, b) => parseInt(a.printer_order) - parseInt(b.printer_order));

        const urlParams = new URLSearchParams(window.location.search);
        const debug = urlParams.get('debug');
        if (debug === 'true') console.log("Copieurs user : ", resultat)
        
        if (!resultat) return null;
    return resultat;
}
/* export const getUserData = async userId => {
    
console.log(userId,"userid")
    const querySnapshot = await firebase.firestore()
        .collection('customers')
        .doc(userId)
        .collection('list_copieurs')
        .where("id", "!=", `electron_${userId}`)
        .get()
console.log("querysnap", querySnapshot)
     const resultat  = querySnapshot.docs.map((doc) => (
               
             {   ...doc.data(),
                id: doc.id,
                //uid: userId
            
            }
        
        ));
        
        if (!resultat) return null;
    return resultat;
} */
