import firebase from 'firebase/compat/app';
import { getCurrentUser} from '../../auth/getCurrentUser';

export const updateInit = async (id, clientUid) => {
   // a l'initialisation on recupere les données de chaque copieur ds firestore et on passe status à todo + type à start

   const currentUser = getCurrentUser();
   if (!currentUser) return null;

/* await    firebase.firestore()
        .collection('customers')
        .doc(clientUid)
        .collection('printing_counters')
        .doc(id)
        .update({"status":"todo", "type":"info"}) */
    
     
      const copDoc = await firebase.firestore().collection('customers')
            .doc(clientUid)
            .collection('printing_counters')
            .doc(id)
               .get()
               const copieurData = copDoc.data();
// console.log("initialisation copieurs", copieurData)
    return {
       type : copieurData.type,
       etatLibre : copieurData.printer_status,
       usageDefault: copieurData.usage_default,
       ipInit:copieurData.printer_ip,
      //  error: copieurData.result_brut.error_status,
       error: copieurData.result_net.error_statut,
       dataInit:copieurData,
       
    };
   
    

    
}
/* export const updateInit = async (id, clientUid) => {
   // a l'initialisation on recupere les données de chaque copieur ds firestore et on passe statut_processing à todo

   const currentUser = getCurrentUser();
   if (!currentUser) return null;

await    firebase.firestore()
        .collection('customers')
        .doc(clientUid)
        .collection('list_copieurs')
        .doc(id)
        .update({"statut_processing":"todo"})
    
     
      const copDoc = await firebase.firestore().collection('customers')
            .doc(clientUid)
            .collection('list_copieurs')
            .doc(id)
               .get()
               const copieurData = copDoc.data();
console.log("initialisation copieurs", copieurData)
    return {
       type : copieurData.result_useful.type,
       etatLibre : copieurData.result_useful.etatLibre,
       ipInit:copieurData.ip_locale,
       error: copieurData.result_useful.error_statut,
       dataInit:copieurData,
       
    };
   
    

    
} */