/* eslint-disable jsx-a11y/anchor-is-valid */
import './Header.css';
import rapido from '../../assets/GestionDesCopieurs.png';


function Header() {
  function refreshPage(){ 
    window.location.reload(); 
}
  return (
    <div className="Header">
        <a onClick={ refreshPage }>
          <img src={rapido} className='ElyoteLogo' alt='ElyoteLogo'></img>
          </a>
    </div>
  );
}

export default Header;
