import React, { useEffect } from 'react';
import './App.css';
// import { signOut } from "../auth/signOut";
import { useTranslation } from 'react-i18next';

  export  const Deconnexion = (props) => {

    const { t } = useTranslation();

    // On supprime les données de connexion dans le local storage
    useEffect(() => {
        localStorage.removeItem('uid');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('client_type');
        // localStorage.removeItem('name');
        // localStorage.removeItem('email');
      }, []);

     /* const logout = async () => {
        try {
            await signOut();
        } catch (e) {
            alert(e.message);
        }
    } */

    // logout();


        return(
            <div className="PhraseDisconnect">
               
               {props.errorMessage
                ? <p>
                    {t('logout.error_id')}
                </p>
                : null}
                <p>{t('logout.message')}
                {/* {' '} <a href="https://bv.elyote.com">{t('logout.link_message')}</a> */}
                </p>
          </div>)
          
         
       
    
 


}

