import React from 'react';
import ReactDOM from 'react-dom/client';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import App from './components/App';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
//import LienAdim from './components/Redirection/LienAdim';
//import LienDisconnect from './components/Redirection/LienDisconnect';
//import LienDocumentation from './components/Redirection/LienDocumentation';
//import idCopieurReleve from './reducers/idCopieurReleve'
//import showReleve1 from './reducers/showReleve1'
//import stateAllButtons from './reducers/stateAllButtons'
import i18n from './util/i18n'; // For changing the language
import { Suspense } from 'react'; // Ajouté pour éviter le crash de l'app quand : user not connecter & try to access /home



let firebaseConfig = {};

if (process.env.NODE_ENV === 'development') {
  firebaseConfig = {
    apiKey: "AIzaSyCc1E8ebuepLsI6gwCngv9jn8H_qb2gyME",
    authDomain: "simplycopy-dev.firebaseapp.com",
    projectId: "simplycopy-dev",
    storageBucket: "simplycopy-dev.appspot.com",
    messagingSenderId: "439274853205",
    appId: "1:439274853205:web:1bb32748a58e596d3a95dc",
    measurementId: "G-SGTTZB9S9X"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyB0j2EqThBWfWArFJgDTlffOZmtGZE3U1g",
    authDomain: "simplycopy-v2.firebaseapp.com",
    projectId: "simplycopy-v2",
    storageBucket: "simplycopy-v2.appspot.com",
    messagingSenderId: "641127203604",
    appId: "1:641127203604:web:69f49ee98b23897061f801",
    measurementId: "G-P3BW70RDJT"
  };
}

  
  // Initialize Firebase

firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ experimentalAutoDetectLongPolling: true,merge:true}); //listening

const auth = firebase.auth();

// Si l'utilisateur n'est pas encore connecté, on récupère la langue de son navigateur et on la passe à i18n
if (!auth.currentUser) {
  const lang = navigator.language.split('-')[0];
  // console.log("langue de l'utilisateur (Navigateur)", lang);
  i18n.changeLanguage(lang);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 // {<React.StrictMode>}
 <React.StrictMode>

 <>
      <Header />
      <Suspense fallback={<div>Loading...</div>}> 
        <App />
      </Suspense>
   
      <Footer />
  </>
  
  </React.StrictMode>
 // {</React.StrictMode>}
);