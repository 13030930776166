import firebase from 'firebase/compat/app';


export const listenResult = (idcop,uid, cb) => {
    const callback = async querySnapshot => {
       //doc ecouté customeruid.doc(idcopieur) champs result_net va changer

        // const copieursEcoutes = querySnapshot.docs.map(doc => ({
        //     ...doc.data(),
        //     id: doc.id,
        // }));
     
        // console.log(copieursEcoutes, "copieurEcoutes")
      
    
        // cb(copieursEcoutes);

        const copDoc = await firebase.firestore().collection('customers')
        .doc(uid)
        .collection('printing_counters')
        .doc(idcop)
           .get()
           const copieurData = copDoc.data();
        // console.log("initialisation copieurs", copieurData)

        // cb(copieurData);

        // transform copieurData to array like this : [copieurData] /////// ONLY TO WORK WITH OLD LOGIQUE IN START1 
        const copieurDataArray = [copieurData];
        // console.log("copieurDataArray", copieurDataArray)
        cb(copieurDataArray);

    
    }

    return firebase.firestore()
    .collection("customers")
    .doc(uid)
    .collection("printing_counters")
    .doc(idcop)
    .onSnapshot(callback);



    // console.log("idcop", idcop)

    // return firebase.firestore()
    // .collection("customers")
    // .doc(uid)
    // .collection("printing_counters")
    // // .where('id', '==', idcop)
    // .onSnapshot(callback);
}

/* export const listenResult = (idcop,uid, cb) => {
    const callback = async querySnapshot => {
       //doc ecouté customeruid.doc(idcopieur) champs result_useful va changer

        const copieursEcoutes = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
        }));
     
        console.log(copieursEcoutes, "copieurEcoutes")
      
    
        cb(copieursEcoutes);
    
    }

    console.log("idcop", idcop)

    return firebase.firestore()
    .collection("customers")
    .doc(uid)
    .collection("list_copieurs")
    .where('id', '==', idcop)
    .onSnapshot(callback);
} */


    